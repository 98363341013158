<template>
  <v-form v-model="valid" lazy-validation ref="form">
    <v-container>
      <v-row>
        <v-col cols="4" md="2">
          <v-select
              v-model="form.currency"
            :items="currencies"
               label="currency"
               required
          ></v-select>
        </v-col>
        <v-col cols="8"  md="3" >
          <v-text-field
            v-model="form.amount"
            :rules="amountRules"
            label="amount"
             :prefix="form.currency"
             min="1"
            type="number"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12"  md="7" >
          <v-text-field
            v-model="form.track_code"
            :rules="track_codeRules"
            label="track_code"
            required
          ></v-text-field>
        </v-col>
        
        <v-col cols="12" md="12">
          <search-store
              field="sigla"
              :store_code.sync="form.store_sigla"></search-store>
        </v-col>
        <v-col cols="4" md="2">
          <v-switch
            v-model="form.switchUser"
            inset
            :label="$t('qrcode.switch.user_code')"
            class="float-right"
          ></v-switch>
        </v-col>
        <v-col cols="8" md="3">
        <v-text-field
            v-if="form.switchUser"
            v-model="form.user_code"
            :rules="user_codeRules"
            label="user_code"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="4"  md="2" >
          <v-switch
            v-model="form.hasproducts"
            inset
            :label="$t('qrcode.switch.products')"
          ></v-switch>
        </v-col>
        <v-col cols="8" md="5" v-if="form.hasproducts">
         <v-combobox multiple
                      v-model="form.products_arr"
                      :label="$t('qrcode.switch.products')"
                      append-icon
                      outlined
                      small-chips
                      deletable-chips
                      class="tag-input"
                      :search-input.sync="search"
                      @keyup.tab="updateCaps"
                      @paste="updateCaps"
                      >
          </v-combobox>
        </v-col>
        <v-col cols="12"  class="text-center" >
        <v-btn
          block
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="submit"
        >
          SUBMIT
        </v-btn>
        </v-col>

        <v-col cols="12"  md="4" class="text-center">
          <v-dialog v-model="qrcode.active" width="500" >
            <v-card>
              <v-card-title class="headline grey lighten-2 align-center"  v-text="qrcode.text">
              </v-card-title>
              <v-card-text class="text-center">
                   <p v-text="form.track_code" class="my-2">  </p>
                        <v-img
                        max-height="400"
                        max-width="400"
                        v-bind:src="qrcode.src"
                        class="mx-auto"
                        ></v-img>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  text
                  color="blue darken-1"
                  @click="qrcode.active = false"
                >Close
                </v-btn>
                <v-btn
                  outlined
                  color="blue darken-1 float-right"
                  :href="qrcode.src"
                  target="_blank"
                  :download="form.track_code+'.png'"
                > Download
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>

      </v-row>
    </v-container>
  </v-form>

</template>

<script>

  import apiClient from "@/plugins/apiClient";
  import moment from 'moment';
  import SearchStore from "@/components/commons/SearchStore";

  function changeCurrency (value) {
        console.log('currency', value)
        if (value == "€") { return "EUR"}
        if (value == "$") { return "USD"}
        if (value == "£") { return "GBP"}
        if (value == "EUR") { return "€"}
        if (value == "GBP") { return "£"}
        if (value == "USD") { return "$"}
  }


  export default {
    data: () => ({
      form: {
        amount: '1.00',
        currency: "€",
        track_code: 'TESTV2' + moment().format('YYYYMMDDhhmmss'),
        store_sigla: '',
        switchUser: false,
        hasproducts: false,
        products_arr: [],
      },
      search: "",
      qrcode: {
        active: false,
        text: "qrCode Scontrino",
        src: null,
      },
      currencies: ['€', '$', '£'],
      valid: false,
      amountRules: [
        v => !!v || 'amount is required',
        v => v >= 1 || 'amount must be more than 1 ',
      ],
      track_codeRules: [
        v => !!v || 'track_code is required',
        v => v.length == 20 || 'track_code must be 20 character',
      ],
      user_codeRules: [
        v => !!v || 'user_code is required',
      ],
      storesRules: [
        v => !!v || 'store is required',
      ],
    }),
    components: {SearchStore},
    methods: {
      updateCaps() {
      this.$nextTick(() => {
        this.select.push(...this.search.split(","));
        this.$nextTick(() => {
          this.search = "";
        });
      });
    },
     async submit () {
        const valid = await this.$refs.form.validate()
         const formvalid = this.form
            formvalid.amount =  formvalid.amount*100;
            formvalid.currency =  changeCurrency(formvalid.currency );
            if (formvalid.products_arr) {
              formvalid.products = formvalid.products_arr.join()
              console.log(formvalid.products);
            }
        if (valid) {
           
            console.log('form is valid: ', valid)
            console.log('form', formvalid)

            await apiClient.post('/qrcodes', formvalid)
            .then(response => {
              
                 console.log('status',response.data, response.status)
                this.qrcode.active = true
                this.qrcode.src = response.data.qr
                // puoi are qualcosa.
                // mostrare il qrcode, svuotare la form, aprire una dialog
            })
            .catch(error => {
               console.log('error ' , error)
               console.log('error ' , error.data)
             
            })

        }

        this.form.amount = this.form.amount/100;
        this.form.track_code = 'TESTV1' + moment().format('YYYYMMDDhhmmss');
        this.form.currency =  changeCurrency(this.form.currency );
      },
    },
  }

</script>

<style scoped>

</style>