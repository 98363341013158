<template>
  <v-sheet class="elevation-3 pa-5">
      <form-title :title="$t('qrcode.bill.old_version')"></form-title>
      <qrcode-form></qrcode-form>
      <form-title :title="$t('qrcode.bill.new_version')"></form-title>
      <qrcode-form-new></qrcode-form-new>
  </v-sheet>
   
</template>

<script>

  import Form from "@/components/qrcode/QrCodeGenerator";
  import FormNew from "@/components/qrcode/QrCodeGeneratorNew";
  import FormTitle from "@/components/commons/FormContentTitle";

  export default {

        name: "Qrcode",

        components: {
          'qrcode-form': Form,
          'qrcode-form-new': FormNew,
          'form-title': FormTitle
        }
    }
</script>

<style lang="scss" scoped>
</style>
