<template>
    <v-sheet class="elevation-3 pa-5">

        <orders-list></orders-list>

    </v-sheet>

</template>

<script>
    import List from "@/components/ecommerce/List";

    export default {

        name: "List",

        data () {
            return {
                search: '',
                openFilters: false,
            }
        },

        components: {
          'orders-list': List
        }
    }
</script>

<style lang="scss" scoped>
</style>
