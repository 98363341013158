<template>
  <v-form v-model="valid" lazy-validation ref="form">
    <v-container>
      <v-row>
        
        <v-col cols="12"  md="6" >
          <v-text-field
            v-model="form.amount"
            :rules="nameRules"
            label="amount in €"
             prefix="€"
             min="1"
            type="number"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12"  md="6" >
          <v-text-field
            v-model="form.track_code"
            :rules="track_codeRules"
            label="track_code"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12"  md="4" >
          <v-switch
            v-model="form.hasproducts"
            inset
            :label="$t('qrcode.switch.products')"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="8" v-if="form.hasproducts">
          <v-combobox multiple
                      v-model="form.products_arr"
                      :label="$t('qrcode.switch.products')"
                      append-icon
                      outlined
                      small-chips
                      deletable-chips
                      class="tag-input"
                      :search-input.sync="search"
                      @keyup.tab="updateCaps"
                      @paste="updateCaps"
                      >
          </v-combobox>
        </v-col>
        <v-col cols="12" class="text-center">
        <v-btn
          block
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="submit"
        >
          SUBMIT
        </v-btn>
        </v-col>
        <v-col cols="12"  md="4" class="text-center">
          <v-dialog v-model="qrcode.active" width="500" >
            <v-card>
              <v-card-title class="headline grey lighten-2 align-center"  v-text="qrcode.text">
              </v-card-title>
              <v-card-text class="text-center">
                   <p v-text="form.track_code" class="my-2">  </p>
                        <v-img
                        max-height="400"
                        max-width="400"
                        v-bind:src="qrcode.src"
                        class="mx-auto"
                        ></v-img>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  text
                  color="blue darken-1"
                  @click="qrcode.active = false"
                >Close
                </v-btn>
                <v-btn
                  outlined
                  color="blue darken-1 float-right"
                  :href="qrcode.src"
                  target="_blank"
                  :download="form.track_code+'.png'"
                > Download
                </v-btn>
              </v-card-actions>

            </v-card>
          </v-dialog>
        </v-col>
        
      </v-row>
    </v-container>
  </v-form>
</template>

<script>

  import moment from 'moment';
  import apiClient from "@/plugins/apiClient";
  
  export default {
    data: () => ({
      form:  {
        amount: '1',
        track_code: 'TESTV1' + moment().format('YYYYMMDDhhmmss'),
        hasproducts: false,
        products_arr: [],
      },
      qrcode: {
        active: false,
        text: "qrCode Scontrino",
        src: 'null',
      },
      search: "",
      valid: false,
      nameRules: [
        v => !!v || 'amount is required',
        v => v >= 1 || 'amount must be more than 1 ',
      ],
      track_codeRules: [
        v => !!v || 'track_code is required',
        v => v.length == 20 || 'track_code must be 20 character',
      ],
    }),

    mounted() {
      let self = this
    },

    methods: {
      updateCaps() {
      this.$nextTick(() => {
        this.select.push(...this.search.split(","));
        this.$nextTick(() => {
          this.search = "";
        });
      });
    },
     async submit () {
        const valid = await this.$refs.form.validate()
        if (valid) {
            const formvalid = this.form
            formvalid.amount =  formvalid.amount*100;
            if (formvalid.products_arr) {
              formvalid.products = formvalid.products_arr.join()
              console.log(formvalid.products);
            }
            console.log('form is valid: ', valid)
            console.log('form', formvalid)

          await apiClient.post('/qrcodes/old-version', formvalid)
          .then(response => {
              this.sending = true
              console.log('status',response.data, response.status)
              this.qrcode.active = true
              this.qrcode.src = response.data.qr
              // puoi are qualcosa.
              // mostrare il qrcode, svuotare la form, aprire una dialog
          })
          .catch(error => {
             console.log('error ' , error)
             console.log('error ' , error.data)
            this.sending = false
            this.valid = false

          })

        }
       
        this.form.amount = this.form.amount/100;
        this.form.track_code = 'TESTV1' + moment().format('YYYYMMDDhhmmss');
      },
    },

  }

</script>

<style scoped>

</style>