<template>
  <div>
    <div v-if="isLoading" align="center" class="py-5">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
    </div>
    <order-details v-if="!isLoading" :item="order"></order-details>
  </div>
</template>

<script>

import OrderDetails from "@/components/ecommerce/_partials/OrderDetails";
import {mapGetters} from "vuex";

export default {
  name: "Details",

  components: {
    OrderDetails
  },

  mounted() {
    this.getOrderDetails()
  },

  computed: {
    ...mapGetters({
      isLoading: 'ecommerce/loadingDetails',
      order: 'ecommerce/getOrder',
      isStoreUser: 'auth/isStoreUser',
    }),
  },

  methods: {
    getOrderDetails() {
      this.$store.dispatch('ecommerce/fetchOrder', this.$route.params.id)
    },
  }
}
</script>

<style scoped>

</style>